/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { withSiteData } from "react-static";
import { Link, scroller } from "react-scroll";
import TagManager from "react-gtm-module";
import { Head } from "../containers";
import { Footer } from "../../common/components";
import { getGTMConfigArgs } from "../../helpers/gtm";

class Home extends Component {
  componentDidMount() {
    // const tagManagerArgs = {
    //   gtmId: 'GTM-KCTJ872'
    // }
    if (window.location.hash) {
      scroller.scrollTo(window.location.hash.replace("#", ""), {
        smooth: true,
        offset: -300,
        duration: 800,
      });
    }
    TagManager.initialize(getGTMConfigArgs("softwaredevtools"));
    window.dataLayer.push(["js", new Date()]);
    window.dataLayer.push([
      "config",
      getGTMConfigArgs("softwaredevtools").gtmId,
    ]);
    window.dataLayer.push({
      platform: "web",
      subcategory: "softwaredevtools",
    });
    // TagManager.dataLayer({
    //   dataLayer:{
    //     'platform':'web',
    //     'subcategory':'softwaredevtools'
    //   }
    // })
  }

  render() {
    const props = this.props;
    return (
      <div className="softwaredevtools">
        <Head title="Agile Software Development Made Easy" />

        <div className="wrapper">
          <div className="page-content">
            <div className="homepage">
              <div className="hero">
                <header>
                  <div className="homepage__header-container">
                    <a href="/">
                      <div className="logo" />
                    </a>
                    <div>
                      <nav className="nav">
                        <ul>
                          <li id="home">
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/about-us">About us</a>
                          </li>
                          <li>
                            <Link
                              to="products"
                              smooth
                              duration={800}
                              alt="products"
                            >
                              Products
                            </Link>
                          </li>
                          <li>
                            <a
                              href="https://blog.catapultlabs.com"
                              target="_blank"
                            >
                              Blog
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </header>

                <div id="first-container" className="homepage__intro">
                  <div className="intro-headers">
                    <h1 className="intro-headers__first">
                      Agile Software Development Made Easy
                    </h1>
                    <h2 className="intro-headers__second">
                      Run efficient sprints with easy-to-use tools for Agile
                      events.
                    </h2>
                  </div>
                  <Link
                    to="products"
                    smooth
                    duration={800}
                    alt="products"
                    className="button"
                    ui-serf="signup"
                  >
                    Improve your Agile practices right now!
                  </Link>
                  {/* <div /> */}
                  <div className="arrow-down">
                    <Link to="products" smooth duration={800} alt="products">
                      <img
                        src={`${props.baseUrlImg}/softwaredevtools/icons/icon-arrow-down-white.svg`}
                        alt="arrow down"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <section id="products" className="container tools">
                <div className="row">
                  <div className="col-md-12 title">
                    <p>
                      Collaborate &amp; Develop software the Agile way with an
                      array of tools.
                    </p>
                  </div>
                </div>
                <div className="row tools-row">
                  <div className="col-sm-6 col-md-3">
                    <a
                      className="tools__item"
                      href="/agile-retrospectives-in-jira-confluence"
                      target="_blank"
                    >
                      <div className="item-logo__container">
                        <img
                          className="item-logo"
                          src={`${props.baseUrlImg}/softwaredevtools/logo-retros.svg`}
                        />
                      </div>
                      <p className="item-description">
                        Run <strong>Retrospectives</strong> within
                      </p>
                      <div className="item-mini-logo">
                        <img
                          className="item-mini-logo__integrations"
                          src={`${props.baseUrlImg}/softwaredevtools/logo-jira.svg`}
                        />
                        <img
                          className="item-mini-logo__integrations item-mini-logo_confluence"
                          src={`${props.baseUrlImg}/softwaredevtools/logo-confluence.svg`}
                        />
                      </div>
                      <div className="learn">Learn more</div>
                    </a>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <a
                      className="tools__item"
                      href="/scrum-poker/jira"
                      target="_blank"
                    >
                      <div className="item-logo__container">
                        <img
                          className="item-logo"
                          src={`${props.baseUrlImg}/softwaredevtools/logo-scrumpoker.svg`}
                        />
                      </div>
                      <p className="item-description">
                        Play <strong>Planning Poker</strong> within
                      </p>
                      <div className="item-mini-logo">
                        <img
                          className="item-mini-logo__integrations"
                          src={`${props.baseUrlImg}/softwaredevtools/logo-jira.svg`}
                        />
                      </div>
                      <div className="learn">Learn more</div>
                    </a>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <a
                      href="/stand-bot"
                      target="_blank"
                      className="tools__item"
                    >
                      <div className="item-logo__container">
                        <img
                          src={`${props.baseUrlImg}/softwaredevtools/logo-standbot.svg`}
                          className="item-logo stand-bot"
                        />
                      </div>
                      <p className="item-description">
                        Run asynchronous standup meetings in{" "}
                        <strong>Slack</strong>
                      </p>
                      <div className="item-mini-logo">
                        <img
                          src={`${props.baseUrlImg}/softwaredevtools/logo-stride.svg`}
                          className="item-mini-logo__integrations"
                        />
                        <img
                          src={`${props.baseUrlImg}/softwaredevtools/garden-logo-stand-bot.svg`}
                          className="item-mini-logo__integrations"
                        />
                        <img
                          src={`${props.baseUrlImg}/softwaredevtools/logo-jira.svg`}
                          className="item-mini-logo__integrations"
                        />
                      </div>
                      <div className="learn">Learn more</div>
                    </a>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <a
                      href="/freshdesk-trello-powerup/"
                      target="_blank"
                      className="tools__item"
                    >
                      <div className="item-logo__container freshdesk-trello">
                        <img
                          src={`${props.baseUrlImg}/softwaredevtools/logo-freshdesk-trello.svg`}
                          className="item-logo"
                        />
                      </div>
                      <p className="item-description">
                        Create &amp; insert <strong>Freshdesk</strong> tickets
                        into <strong>Trello</strong> cards
                      </p>
                      <div className="item-mini-logo">
                        <img
                          className="item-mini-logo__integrations"
                          src={`${props.baseUrlImg}/softwaredevtools/garden-logo-freshdesk.svg`}
                        />
                      </div>
                      <div className="learn">Learn more</div>
                    </a>
                  </div>
                </div>
                <div
                  className="row tools-row"
                  style={{ justifyContent: "center" }}
                >
                  <div className="col-sm-6 col-md-3">
                    <a
                      className="tools__item"
                      href="/scrum-poker/"
                      target="_blank"
                    >
                      <div className="item-logo__container">
                        <img
                          className="item-logo"
                          src={`${props.baseUrlImg}/softwaredevtools/logo-scrumpoker.svg`}
                        />
                      </div>
                      <p className="item-description">
                        Play <strong>Planning Poker</strong> within
                      </p>
                      <div className="item-mini-logo">
                        <img
                          className="item-mini-logo__integrations"
                          src={`${props.baseUrlImg}/softwaredevtools/logo-confluence.svg`}
                        />
                      </div>
                      <div className="learn">Learn more</div>
                    </a>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <a
                      href="/freshservice/trello-app/"
                      target="_blank"
                      className="tools__item"
                    >
                      <div className="item-logo__container freshdesk-trello">
                        <img
                          src={`${props.baseUrlImg}/softwaredevtools/logo-freshservice-trello.svg`}
                          className="item-logo"
                        />
                      </div>
                      <p className="item-description">
                        Create & insert <strong>Freshservice</strong> tickets
                        into <strong>Trello</strong> cards
                      </p>
                      <div className="item-mini-logo">
                        <img
                          className="item-mini-logo__integrations"
                          src={`${props.baseUrlImg}/softwaredevtools/garden-logo-freshservice-trello.png`}
                        />
                      </div>
                      <div className="learn">Learn more</div>
                    </a>
                  </div>
                </div>
              </section>

              <section className="agile-team">
                <div className="agile-team__wrapper">
                  <div className="agile-team__first">
                    <div className="agile-team__img">
                      <img
                        className="agile-team__img"
                        src={`${props.baseUrlImg}/softwaredevtools/graphic-figure-boy-1.svg`}
                      />
                    </div>
                  </div>
                  <div className="agile-team__text">
                    <p>
                      Make Agile events rewarding and fun. Gear up your team.
                    </p>
                  </div>
                </div>
              </section>

              <section className="agile-practices">
                <div className="title">
                  SoftwareDevTool's Blog. Stay updated about everything Agile,
                  Atlassian news &amp; more.
                </div>
                <div className="agile-practices__stories-wrapper">
                  <a
                    href="https://blog.catapultlabs.com/collaboration-the-key-to-great-customer-service"
                    target="_blank"
                  >
                    <div className="agile-practices__story agile-practices__collaboration">
                      <div className="story-description">
                        <p className="story-title">
                          Collaboration: The Key to great customer service.
                        </p>
                        <p className="story-text">
                          You’ll find that collaboration is easy enough as long
                          as they are on the same team. The funnel comes when
                          there’s need for collaboration between different
                          teams.
                          <br />
                          <span>Read more</span>
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://blog.catapultlabs.com/custom-agile-retrospectives"
                    target="_blank"
                  >
                    <div className="agile-practices__story agile-practices__custom-retros">
                      <div className="story-description">
                        <p className="story-title">
                          Custom Retrospectives: One size does not fit all.
                        </p>
                        <p className="story-text">
                          Catapult Labs is all about helping remote teams adopt
                          Agile methodologies in an easier and more effective
                          way. That means having an engaging and productive
                          session, EVERY time. And what's engaging for some, may
                          not be interesting for others...
                          <br />
                          <span>Read more</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <a
                  className="button"
                  href="https://blog.catapultlabs.com"
                  target="_blank"
                >
                  MORE STORIES
                </a>
              </section>
            </div>
          </div>
        </div>
        {/* start footer */}
        <div className="bk-ship-gray base-font">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSiteData(Home);
