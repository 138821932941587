import React from 'react';
import { Head as HeadComponent} from '../components';
import { withSiteData } from 'react-static';


class Head extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    return (
      <HeadComponent title={this.props.title}/>
    )
  }
}

export default withSiteData(Head);

