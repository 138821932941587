import React from "react";
import { Head, withSiteData } from "react-static";

export default withSiteData((props) => (
  <Head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta property="og:url" content="https://apps.catapultlabs.com/" />
    <meta property="og:type" content="product.group" />
    <meta property="og:title" content="Agile Software Development Made Easy" />
    <meta
      property="og:description"
      content="Collaborate &amp; Develop software the Agile way with an array of tools.. Make Agile rewarding and fun. Gear up your team &amp; optimize all stages of software development"
    />
    <meta
      property="og:image"
      content={`${props.baseUrlImg}/elements/sdt-hero-share.jpeg`}
    />
    <meta
      name="description"
      content="Collaborate &amp; Develop software the Agile way with an array of tools to make it rewarding and fun. Gear up your team &amp; optimize all stages of Agile Software Development"
    />
    <meta
      name="google-site-verification"
      content="DJB1cT9U1izrEOLbgkaFPd42eiKVyB_UPdkDyNkTvzQ"
    />
    <meta
      name="keywords"
      content="Agile retrospectives, remote agile teams, Scrum master, software development, Atlassian, agile software development, agile methodologies"
    />

    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-57x57.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-60x60.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-72x72.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-76x76.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-114x114.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-120x120.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-144x144.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-152x152.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/apple-icon-180x180.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/android-icon-192x192.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/favicon.ico`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/favicon.ico`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={`${props.baseUrlImg}/favicons/softwaredevtools/favicon.ico`}
    />
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta
      name="msapplication-TileImage"
      content={`${props.baseUrlImg}/favicons/softwaredevtools/ms-icon-144x144.png`}
    />
    <meta name="theme-color" content="#ffffff" />

    <title>{props.title}</title>

    <link
      type="text/css"
      rel="stylesheet"
      href="//fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700"
    />
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
      integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
      crossorigin="anonymous"
    />
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
      integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
      crossorigin="anonymous"
    />

    {/* Autopilot encoraapps capture code */}
    <script>
      {`
        window.ap3c = window.ap3c || {};
        var ap3c = window.ap3c;
        ap3c.cmd = ap3c.cmd || [];
        ap3c.cmd.push(function() {
            ap3c.init('YhgJT8Cii0MoVy-hZW5jb3JhYXBwcw', 'https://capture-api.autopilotapp.com/');
            ap3c.track({v: 0});
        });
        var s, t; s = document.createElement('script'); s.type = 'text/javascript'; s.src = "https://cdn2l.ink/app.js";
        t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);
    `}
    </script>
  </Head>
));
